import * as React from "react";
import Svg, { Path } from "react-native-svg";

const Yellow = ({ color = "#333" }) => (
  <Svg
    width="43"
    height="37"
    viewBox="0 0 43 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6959 36.8604C5.68441 36.8604 0 30.8869 0 23.5189C0 22.1073 0.209929 20.7465 0.596711 19.4697L0.595829 19.4683C0.595829 19.4683 3.33812 4.41 18.9989 0C18.9989 0 14.5953 4.18588 12.3245 10.1844C12.4475 10.1807 12.5715 10.1779 12.6958 10.1779C14.7404 10.1779 16.6717 10.6863 18.3824 11.5887C17.6168 13.0333 16.9596 14.5497 16.4379 16.0999C16.201 16.8053 15.8456 17.8351 15.7106 18.7949C15.2625 20.3252 15.0363 21.9172 15.0363 23.5189C15.0363 27.9221 16.7254 31.9115 19.4594 34.8105C17.5012 36.1085 15.1827 36.8603 12.6958 36.8603L12.6959 36.8604ZM30.3042 36.8604C23.2927 36.8604 17.6083 30.8869 17.6083 23.5189C17.6083 22.1073 17.8182 20.7465 18.205 19.4697L18.2041 19.4683C18.2041 19.4683 20.9464 4.41 36.6072 0C36.6072 0 32.2036 4.18588 29.9328 10.1844C30.0558 10.1807 30.1797 10.1779 30.3041 10.1779C37.3164 10.1779 43 16.1514 43 23.5195C43 30.8875 37.3165 36.861 30.3041 36.861L30.3042 36.8604Z"
      fill="#FED168"
    />
  </Svg>
);
export default Yellow;
