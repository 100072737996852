import * as React from "react"
import Svg, { Path } from "react-native-svg"

const Linkedin = ({color='#333'}) => (
  <Svg width="24" height="25" viewBox="0 0 24 25" fill={color} xmlns="http://www.w3.org/2000/svg">
  <Path d="M20.9119 0H2.82593C1.26602 0 0 1.33333 0 2.97619V22.0238C0 23.6667 1.26602 25 2.82593 25H20.9119C22.4718 25 23.7378 23.6667 23.7378 22.0238V2.97619C23.7378 1.33333 22.4718 0 20.9119 0ZM7.34742 9.52381V20.8333H3.95631V9.52381H7.34742ZM3.95631 6.23214C3.95631 5.39881 4.63453 4.7619 5.65187 4.7619C6.6692 4.7619 7.30786 5.39881 7.34742 6.23214C7.34742 7.06548 6.71442 7.7381 5.65187 7.7381C4.63453 7.7381 3.95631 7.06548 3.95631 6.23214ZM19.7815 20.8333H16.3904C16.3904 20.8333 16.3904 15.3214 16.3904 14.881C16.3904 13.6905 15.8252 12.5 14.4123 12.4762H14.367C12.9993 12.4762 12.4341 13.7024 12.4341 14.881C12.4341 15.4226 12.4341 20.8333 12.4341 20.8333H9.04298V9.52381H12.4341V11.0476C12.4341 11.0476 13.5249 9.52381 15.7178 9.52381C17.9616 9.52381 19.7815 11.1488 19.7815 14.4405V20.8333Z" fill="#333333"/>
  </Svg>
  
)
export default Linkedin
